<template>
  <div class="table_wrapper">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>
          Category
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" editable>
          Product Info
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 3" step="3" editable>
          Description
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 4" step="4" editable>
          Attributes
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 5" step="5" editable>
          Prices
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 6" step="6" editable>
          Photos
        </v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step step="7" editable>
          Ready
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="p-3">
            <v-row align="center">
              <v-col
                cols="3"
                v-for="(item, index) in form.categories"
                :key="index"
              >
                <v-flex>
                  <v-select
                    :items="item.data"
                    label="Select Category"
                    v-model="item.id"
                    append-icon="mdi-close"
                    @click:append="form.categories.splice(index, 1)"
                    item-text="title"
                    item-value="id"
                    @change="getListSub"
                    dense
                    return-object
                  >
                  </v-select>
                </v-flex>
              </v-col>
            </v-row>

            <v-btn
              class="ml-3 mr-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
            <v-btn color="primary" @click="e1 = 2">
              Next
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="p-3">
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="form.title"
                  label="Title"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="form.unit"
                  :items="units"
                  label="Select Unit"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="form.currency"
                  :items="currencies"
                  label="Select Currency"
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-select>
              </v-col>
            </v-row>

            <v-btn text @click="e1 = 1">
              Prev
            </v-btn>
            <v-btn
              class="ml-3 mr-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
            <v-btn color="primary" class="ml-3" @click="e1 = 3">
              Next
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-3 p-3"
            elevation="2"
            outlined
            v-for="(d, index) in form.descriptions"
            :key="index"
          >
            <v-text-field
              v-model="d.title"
              :label="'Title ' + index.toUpperCase()"
              required
            ></v-text-field>

            <tiptap-vuetify
              v-model="d.description"
              :extensions="extensions"
              :placeholder="'Description ' + index.toUpperCase()"
            />
          </v-card>

          <v-btn text @click="e1 = 2">
            Prev
          </v-btn>
          <v-btn
            class="ml-3 mr-3"
            color="green"
            dark
            :disabled="dialog"
            :loading="dialog"
            v-on:click="saveAllData"
          >
            Save
          </v-btn>
          <v-btn color="primary" class="ml-3" @click="e1 = 4">
            Next
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card class="p-3">
            <h5>Please Continue</h5>
            <v-btn text @click="e1 = 3">
              Prev
            </v-btn>
            <v-btn
              class="ml-3 mr-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
            <v-btn color="primary" class="ml-3" @click="e1 = 5">
              Next
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card class="p-3">
            <v-btn color="pink" dark class="mb-3" @click="addPrices">
              <v-icon>mdi-plus</v-icon>
              <span>More</span>
            </v-btn>
            <v-card
              class="mb-3 p-3"
              elevation="0"
              outlined
              v-for="(p, index) in form.prices"
              :key="index"
            >
              <div class="text-overline mb-4">ITEM ID #{{ index + 1 }}</div>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="p.min"
                    :label="'Min Quantity'"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="p.max"
                    :label="'Max Quantity'"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="p.price"
                    :label="'Price Per Unit'"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions
                v-if="index > 0"
                v-on:click="form.prices.splice(index, 1)"
              >
                <v-btn color="red" text>
                  <v-icon>mdi-close</v-icon>
                  <span>Remove</span>
                </v-btn>
              </v-card-actions>
            </v-card>

            <v-btn text @click="e1 = 4">
              Prev
            </v-btn>
            <v-btn
              class="ml-3 mr-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
            <v-btn color="primary" class="ml-3" @click="e1 = 6">
              Next
            </v-btn>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="6">
          <v-card class="mb-3 p-3" elevation="0" outlined>
            <h6>Add New</h6>
            <v-file-input
              v-model="files"
              color="deep-purple accent-4"
              counter
              label="File input"
              multiple
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
              outlined
            >
            </v-file-input>
            <v-btn
              color="blue"
              v-if="files.length > 0"
              dark
              :disabled="isImageLoad"
              v-on:click="uploadImage"
            >
              <v-icon>
                mdi-upload
              </v-icon>
              <span>Upload</span>
            </v-btn>
          </v-card>

          <v-card class="mb-3 p-3" elevation="0" outlined>
            <h6>
              Uploaded Images
            </h6>
            <input
              type="file"
              name="file"
              id="file"
              class="d-none"
              v-on:change="syncImageUpload"
            />
            <v-row>
              <v-col cols="2" v-for="(img, index) in form.images" :key="index">
                <v-img
                  :src="img.photo"
                  max-width="250"
                  max-height="250"
                ></v-img>
                <v-btn text block class="mb-2" v-on:click="syncImage(img)">
                  <v-icon>
                    mdi-sync
                  </v-icon>
                </v-btn>
                <v-btn text block v-on:click="form.images.splice(index, 1)">
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>

          <v-btn text @click="e1 = 5">
            Prev
          </v-btn>
          <v-btn
            class="ml-3 mr-3"
            color="green"
            dark
            :disabled="dialog"
            :loading="dialog"
            v-on:click="saveAllData"
          >
            Save
          </v-btn>
          <v-btn color="primary" class="ml-3" @click="e1 = 7">
            Next
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="7">
          <v-card class="p-3">
            <h3 class="mb-4 light-blue--text">
              <span class="font-weight-bold">You came to last step.</span>
              <span class="font-weight-light">If you're ready, </span>
              <span class="font-weight-black">let's save!</span>
            </h3>
            <v-btn @click="e1 = 1" text>
              Return to 1
            </v-btn>
            <v-btn @click="e1 = 6" class="ml-3">
              Prev
            </v-btn>
            <v-btn
              class="ml-3 mr-3"
              color="green"
              dark
              :disabled="dialog"
              :loading="dialog"
              v-on:click="saveAllData"
            >
              Save
            </v-btn>
          </v-card>

          <v-dialog v-model="dialog" hide-overlay persistent width="300">
            <v-card color="primary" dark>
              <v-card-text>
                Loading.. Please wait.
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  CodeBlock,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  name: "ProductsEdit",
  watch: {
    dialog(val) {
      if (!val) return;
      setTimeout(
        () => (this.dialog = false && this.$router.push("/Products")),
        4000
      );
    },
  },
  components: { TiptapVuetify },
  data() {
    return {
      e1: 1,
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      isImageLoad: false,
      dialog: true,
      catTree: [],
      threeCount: 0,
      files: [],
      form: {
        id: null,
        title: "",
        unit: null,
        currency: null,
        categories: [],
        images: [],
        prices: [
          {
            min: 1,
            max: null,
            price: "",
          },
        ],
        descriptions: {
          tr: {
            title: "",
            description: "",
          },
          en: {
            title: "",
            description: "",
          },
          ar: {
            title: "",
            description: "",
          },
        },
      },
      units: [],
      currencies: [],
      syncImageItem: null,
      token: localStorage.getItem("authToken"),
    };
  },
  methods: {
    saveAllData: function() {
      this.dialog = true;
      this.form.id = Number(this.$route.params.id);
      let formAllData = this.form;

      formAllData.unit_id = this.form.unit.id;
      formAllData.currency_id = this.form.currency.id;

      axios
        .put(process.env.VUE_APP_API_URL + "product/" + this.$route.params.id, {
          formAllData,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
    uploadImage: function() {
      const filesData = this.files[0];
      let formDataClass = new FormData();
      formDataClass.append("file", filesData);

      try {
        this.isImageLoad = true;
        axios
          .post(process.env.VUE_APP_API_URL + "upload", formDataClass, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.form.images.push({
              photo: res.data.path,
            });
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    },
    syncImage: function(item) {
      const mfile = document.getElementById("file");
      mfile.click();
      this.syncImageItem = item;
    },
    syncImageUpload: function(item) {
      const filesData = item.target.files[0];

      let formDataClass = new FormData();
      formDataClass.append("file", filesData);

      try {
        axios
          .post(process.env.VUE_APP_API_URL + "upload", formDataClass, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.syncImageItem.photo = res.data.path;
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error);
      }
    },
    addPrices: function() {
      this.form.prices.push({
        min: 1,
        max: null,
        per: 1,
      });
    },
    removeItems: function(a) {
      this.form.categories.splice(a + 1, this.form.categories.length - 1);
    },
    getListSub: function(item) {
      this.form.categories.find((a, index) =>
        a?.data.find((b) =>
          b.id == item.id ? (a.id = item.id) && this.removeItems(index) : null
        )
      );

      axios
        .get("https://sourcing.gowawe.com/en/ajax/categories/child/" + item.id)
        .then((res) => {
          const response = res.data.data;
          if (response.length > 0) {
            this.form.categories.push({
              data: response,
              id: item.id,
            });
          }
        });
    },
    getList: function() {
      const categoryId = this.catTree[this.threeCount];

      const categoryIdChild = this.catTree[this.threeCount + 1];

      if (this.threeCount > 0) {
        if (categoryId != undefined) {
          axios
            // .get("https://sourcing.gowawe.com/en/ajax/categories/" + categoryId)
            .get("https://gowawe.com/api/en/categories/" + categoryId)
            .then((res) => {
              const response = res.data.data;
              if (response.length > 0) {
                this.form.categories.push({
                  data: response,
                  id: categoryIdChild,
                });
                this.threeCount++;
                this.getList();
              }
            });
        }
      } else {
        axios.get("https://gowawe.com/api/en/categories").then((res) => {
          const response = res.data.data;
          if (response.length > 0 && categoryId != undefined) {
            this.form.categories.push({
              data: response,
              id: categoryId,
            });

            axios
              .get("https://gowawe.com/api/en/categories/" + categoryId)
              .then((res) => {
                const response = res.data.data;
                if (response.length > 0 && categoryIdChild != undefined) {
                  this.form.categories.push({
                    data: response,
                    id: categoryIdChild,
                  });
                  this.threeCount++;
                  this.getList();
                }
              });
          }
        });
      }
    },
    getUnits: function() {
      let url = "https://gowawe.com/api/units";
      axios.get(url).then((res) => {
        const response = res.data.data;
        if (response.length > 0) {
          this.units = response;
        }
      });
    },
    getCurrencies: function() {
      let url = "https://dashboard.gowawe.com/getCurreny";
      axios.post(url).then((res) => {
        const response = res.data;
        if (response.length > 0) {
          this.currencies = response;
        }
      });
    },
    getData: function(id) {
      let url = "https://gowawe.com/api/admin/product/" + id;

      axios
        .get(url)
        .then((res) => {
          const resp = res.data.res;
          this.form.title = resp.title;
          this.form.unit = resp.unit.id;
          this.form.currency = resp.currency_id;

          this.form.prices = resp.product_prices;
          this.form.images = resp.product_photos;

          this.catTree = res.data.catTree;
          this.getList();

          Object.keys(resp.translations).forEach((item, index) => {
            const vals = Object.values(resp.translations)[index];

            if (item == "tr") {
              this.form.descriptions.tr.title = vals.title;
              this.form.descriptions.tr.description = vals.description;
            }
            if (item == "en") {
              this.form.descriptions.en.title = vals.title;
              this.form.descriptions.en.description = vals.description;
            }
            if (item == "ar") {
              this.form.descriptions.ar.title = vals.title;
              this.form.descriptions.ar.description = vals.description;
            }
          });

          this.dialog = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getUnits();
    this.getCurrencies();
    this.getData(this.$route.params.id);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
